<template>
  <div class="home">
    <div class="all-title">支付</div>
    <div
      class="common-back"
      @click="backAction"
    >
      <img
        src="https://artimg2.artart.cn/image/20220428/c2e0865b1ace1cb7c56e7427381c234e.png"
        alt=""
      >
    </div>
    <template v-if="nftData">
      <div class="nft-info flex">
        <div class="nft-l">
          <van-image
            class="img"
            :src="nftData.product.image"
            fit="contain"
          ></van-image>
        </div>
        <div class="nft-r">
          <div class="name line1">{{ nftData.product.store_name }}</div>
          <div class="price">￥<span>{{ formatPriceNumber(nftData.product.price, 2) }}</span></div>
        </div>
      </div>
      <div class="deduction-amount">
        <div class="t">抵扣金额</div>
        <div class="c flex align-items-c">
          <div class="icon">
            <img
              src="https://artimg2.artart.cn/image/20220502/fc729558f634cb9fcda962b8b39f4030.png"
              alt=""
            >
          </div>
          <div class="c-txt">钱包余额 <span>{{ formatPriceNumber(account, 2) }}元可用</span></div>
          <div class="inp">
            <input
              v-model="use_wallet_price"
              type="text"
              placeholder="请输入金额"
            >
          </div>
        </div>
        <div
          v-if="entrust_account"
          class="c flex align-items-c"
        >
          <div class="icon">
            <img
              src="https://artimg2.artart.cn/image/20220502/fc729558f634cb9fcda962b8b39f4030.png"
              alt=""
            >
          </div>
          <div class="c-txt">委托方余额 <span>{{ formatPriceNumber(entrust_account, 2) }}元可用</span></div>
          <div class="inp">
            <input
              v-model="use_entrust_wallet_price"
              type="text"
              placeholder="请输入金额"
            >
          </div>
        </div>
      </div>
      <div class="deduction-amount">
        <div class="t">支付方式</div>
        <div class="c flex align-items-c">
          <div class="icon">
            <img
              src="https://artimg2.artart.cn/image/20220502/757f2afeaed9a13f6d3ad3785f004166.png"
              alt=""
            >
          </div>
          <div class="c-txt">微信支付</div>
          <div class="chiose">
            <img
              v-if="payType == 'wx'"
              src="https://artimg2.artart.cn/image/20220501/b4765cb54b83166da4eee287a876f9cc.png"
              alt=""
            >
            <img
              v-else
              src="https://artimg2.artart.cn/image/20220501/7a96feee530103d0dcd39808f7e189db.png"
              alt=""
            >
          </div>
        </div>
      </div>
      <div class="pay-btns item-flex-center">
        <div
          class="btn"
          @click="goPayAction"
        >立即支付</div>
      </div>
    </template>
  </div>
</template>

<script>
import { Image as VanImage, Toast } from "vant";
import { ref, onBeforeMount, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { createNftApi } from "@/api/nft";
import { getNftOrderSettlementInfoApi } from "@/api/order";
import { useStore } from "vuex";
import { formatDateNumber, formatPriceNumber, payAction } from "@/utils/util";

export default {
  components: {
    [VanImage.name]: VanImage,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const id = ref("");
    const use_wallet_price = ref("");
    const use_entrust_wallet_price = ref("");
    const payType = ref("wx");
    onBeforeMount(() => {
      id.value = route.query.id;
      store.dispatch("user/getAccountInfo");
      store.dispatch("user/getEntrustAccountInfo");

      if (!id.value) {
        Toast("出现错误，请返回重试");
        router.back();
        return false;
      }

      loadDetail();
    });

    const nftData = ref("");
    const account = ref("");
    const entrust_account = ref("");

    const loadDetail = () => {
      getNftOrderSettlementInfoApi({ product_id: id.value })
        .then((res) => {
          nftData.value = res.data.nft_info;
          account.value = res.data.userWalletInfo.balance;
          entrust_account.value = res.data.entrustWalletInfo.balance;

          if (account.value > Number(res.data.nft_info.product.price)) {
            use_wallet_price.value = Number(res.data.nft_info.product.price);
          } else if (account.value > 0) {
            use_wallet_price.value = account.value;
          }
        })
        .catch((message) => {
          Toast(message);
          router.back();
        });
    };

    watch(use_wallet_price, (val) => {
      if (
        val > account.value ||
        val >
          Number(nftData.value.product.price) -
            use_entrust_wallet_price.value ||
        isNaN(val)
      ) {
        if (
          account.value >
          Number(nftData.value.product.price) - use_entrust_wallet_price.value
        ) {
          use_wallet_price.value =
            Number(nftData.value.product.price) -
            use_entrust_wallet_price.value;
        } else {
          use_wallet_price.value = account.value;
        }
      }
    });
    watch(use_entrust_wallet_price, (val) => {
      if (
        val > account.value ||
        val > Number(nftData.value.product.price) - use_wallet_price.value ||
        isNaN(val)
      ) {
        if (
          account.value >
          Number(nftData.value.product.price) - use_wallet_price.value
        ) {
          use_entrust_wallet_price.value =
            Number(nftData.value.product.price) - use_wallet_price.value;
        } else {
          use_entrust_wallet_price.value = account.value;
        }
      }
    });

    const changeType = (val) => {
      if (val === "yue") {
        if (account.value.balance < nftData.value.product.price) {
          return false;
        }
      }
      if (val === "wtfyue") {
        if (entrust_account.value.balance < nftData.value.product.price) {
          return false;
        }
      }
      payType.value = val;
    };

    let payLock = false;

    const goPayAction = () => {
      if (isNaN(use_wallet_price.value)) {
        Toast("请输入正确的金额");
        return false;
      }
      // createNftApi
      const data = {
        product_id: id.value,
        pay_type: "wechat_h5",
        use_wallet_price: use_wallet_price.value || "",
        use_entrust_wallet_price: use_entrust_wallet_price.value || "",
      };

      if (payLock) return false;
      payLock = true;
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });

      createNftApi(data)
        .then((res) => {
          // console.log(res.data.data.pay_result.appId)
          if (res.data.status == 1) {
            Toast.clear();
            Toast("支付成功！");
            setTimeout(() => {
              payLock = false;
              router.replace("/users/my_nft");
            }, 1000);
          } else {
            Toast.clear();
            payAction(
              res.data.result.config,
              () => {
                Toast("支付成功");
                setTimeout(() => {
                  payLock = false;
                  router.replace("/users/my_nft");
                }, 1000);
              },
              () => {
                payLock = false;
              },
              () => {
                payLock = false;
              }
            );
          }
        })
        .catch((data) => {
          Toast.clear();
          if (data.message === "未开通数字藏品账户") {
            router.push("/users/register_nft");
          } else {
            Toast(data.message);
          }
          payLock = false;
        });
    };

    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    };

    return {
      account,
      entrust_account,
      nftData,
      formatPriceNumber,
      use_wallet_price,
      use_entrust_wallet_price,
      payType,

      backAction,
      goPayAction,
      changeType,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #1e1e1e;
  padding-bottom: 198px;
  padding-top: 110px;
  max-height: 99999px;

  .nft-info {
    margin: 32px 64px 0 64px;
    background: #333333;
    border-radius: 8px;
    border: 2px solid #444444;
    padding: 24px 26px;

    .nft-l {
      width: 96px;
      height: 96px;
      border-radius: 8px;
      .img {
        width: 96px;
        height: 96px;
        border-radius: 8px;
        background: #999;
      }
    }
    .nft-r {
      margin-left: 18px;
      flex: 1;
      overflow: hidden;

      .name {
        font-size: 32px;
        font-weight: 500;
        color: #ffffff;
        line-height: 44px;
      }
      .price {
        font-size: 28px;
        color: #ffffff;
        margin-top: 14px;

        span {
          font-size: 40px;
          font-family: NUM;
        }
      }
    }
  }

  .deduction-amount {
    padding: 48px 64px 0 64px;
    .t {
      height: 40px;
      font-size: 28px;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
      padding-bottom: 16px;
    }
    .c {
      margin-top: 32px;
      .icon {
        img {
          width: 48px;
          height: 50px;
          display: block;
        }
      }
      .c-txt {
        font-size: 28px;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        flex: 1;
        margin-left: 16px;

        span {
          color: #999;
          font-size: 24px;
          margin-left: 10px;
        }
      }
      .inp {
        width: 136px;
        height: 64px;
        background: #333333;
        border-radius: 8px;
        border: 2px solid #666666;
        padding: 0 24px;

        input {
          width: 100%;
          padding: 0;
          margin: 0;
          border: none;
          background: none;
          line-height: 64px;
          text-align: right;

          font-size: 32px;
          font-weight: 500;
          color: #fff;

          &::-webkit-input-placeholder {
            font-size: 26px;
          }
        }
      }
      .chiose {
        img {
          width: 48px;
          height: 48px;
          display: block;
        }
      }
    }
  }
  .pay-btns {
    position: fixed;
    bottom: 98px;
    width: 100%;

    .btn {
      width: 300px;
      height: 96px;
      background: #00eeff;
      border-radius: 48px;
      text-align: center;
      line-height: 96px;
      font-size: 32px;
      color: #000;
      font-weight: 500;
    }
  }
}
</style>
